import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { get, pick, set, startsWith } from "lodash";
import Slider from "react-slick";

import { makeStyles } from "../AppContainer/mui-theme";
import AppContainer from "../AppContainer";
import PurchasePoller from "../purchase-poller";
import Loader from "../Loader";
import QRCode from "./qrCode";
import StarIcon from "./svg/star.svg";
import DollarIcon from "./svg/dollar.svg";
import PaymentMethodForm from "./paymentMethodForm";

export default ({
  T,
  appStyles,
  user,
  app,
  order,
  paymentTypeDetails,
  pageContext,
  pageContext: {
    business: { currencySymbol },
    businessAppConfiguration: { separateQRCodeForPayment }
  },
  loadPaymentMethods,
  loadLoyaltyProfile,
  hasChargeCard,
  hasChargeCardDiscount,
  location,
}) => {
  const context = {
    appStyles,
    user,
    pageContext,
    app,
    order,
    paymentTypeDetails,
    loadPaymentMethods,
    loadLoyaltyProfile,
    hasChargeCard,
    hasChargeCardDiscount,
    location,
  };
  const [loaded, setLoaded] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [showAddCardForm, setShowAddCardForm] = useState(false);
  const { classes } = useStyles(appStyles);

  const openChargeCardInstance = get(
    user,
    "loyaltyProfile.data.openChargeCardInstance"
  );

  const amountOnCard = openChargeCardInstance
    ? openChargeCardInstance.loadedAmount - openChargeCardInstance.usedAmount
    : 0;

  const rewardPointBalance = get(
    user,
    "loyaltyProfile.data.rewardPointBalance.balance"
  );

  useEffect(() => {
    setTimeout(() => {
      console.log({ loaded });
      setLoaded(true);
    }, 300);
  }, []);

  useEffect(() => {
    if (!user.loggedIn) return;

    loadPaymentMethods(paymentTypeDetails.paymentType);
  }, [user.loggedIn, paymentTypeDetails.paymentType]);

  const userPhoneNumber = get(user, "userDetails.data.phoneNumber");
  const externalUserId = get(user, "userDetails.data.externalUserId");

  const qrCodeValue = appStyles.scanWithPhoneNumber
    ? startsWith(userPhoneNumber, "1")
      ? userPhoneNumber.replace("1", "")
      : userPhoneNumber
    : externalUserId;

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    setShowAddCardForm(false);
  };

  const handleShowAddCardForm = () => {
    setShowAddCardForm(!showAddCardForm);
  };

  const sliderRef = useRef(null);
  const dotsRef = useRef(null);

  const paymentMethods = useMemo(() => {
    const paymentMethodsData = (get(user, "paymentMethods.data") || []).map(
      (paymentMethod) =>
        pick(paymentMethod, [
          "displayString",
          "token",
          "fullName",
          "expirationMonth",
          "expirationYear",
        ])
    );
    paymentMethodsData.push({ isPlaceholder: true });

    return paymentMethodsData;
  }, [user.paymentMethods.data]);

  let isLastDot = false;
  const settings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 1.05, // to show a part of next slide
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    focusOnSelect: true,
    // TODO: at some point dots highlighted in a wrong way, temporal fix
    appendDots: (dots) => {
      if (isLastDot) {
        set(dotsRef.current, `children[${paymentMethods.length - 1}].className`, "slick-active");
      } else {
        set(dotsRef.current, `children[${paymentMethods.length - 1}].className`, "");
      }
      return (
        <div>
          <ul ref={dotsRef} style={{ margin: 0, padding: 0 }}>
            {" "}
            {dots}{" "}
          </ul>
        </div>
      );
    },
    beforeChange: (_, next) =>
      (isLastDot = Math.round(next) === paymentMethods.length - 1),
  };

  const qrCodeContent = useMemo(() => {
    const renderQRCodeCard = (value, isPlaceholder, displayString) => (
      <Box className={classes.qrCodeCard} key={displayString || "placeholder"}>
        <QRCode
          value={value}
          fgColor={isPlaceholder ? "#0000001A" : undefined}
        />
        {isPlaceholder ? (
          <Button
            onClick={handleShowAddCardForm}
            variant="outlined"
            size="small"
            sx={{
              background: appStyles.backgroundColor || "#FFFFFF",
              textTransform: "none",
              marginTop: "10px",
              borderRadius: "10px",
              fontSize: "12px",
              color: appStyles.textColor || "#000000",
            }}
          >
            {T("Add Card")}
          </Button>
        ) : (
          <Typography
            component="div"
            sx={{
              fontSize: "12px",
              color: get(appStyles, "PaymentMethodCard.color", "#FFFFFF"),
              marginTop: "10px",
            }}
          >
            {`Card - **** **** **** ${displayString}`}
          </Typography>
        )}
      </Box>
    );

    return paymentMethods.length > 1 ? (
      <Slider ref={sliderRef} {...settings} className={classes.slider}>
        {paymentMethods.map(({ displayString, token, isPlaceholder }) => (
          <div>
            {renderQRCodeCard(
              isPlaceholder ? "placeholder" : `payment_${qrCodeValue}_${token}`,
              isPlaceholder,
              displayString
            )}
          </div>
        ))}
      </Slider>
    ) : (
      renderQRCodeCard("placeholder", true)
    );
  }, [paymentMethods]);

  if (!user.loggedIn) {
    return (
      <AppContainer.Content
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        appStyles={appStyles}
      >
        <Typography className={classes.title}>
          {T("Login to scan when you visit the store")}
        </Typography>
      </AppContainer.Content>
    );
  }

  if (app.isSSR || !loaded 
    //TODO uncomment when PurchasePoller component moved or updated fetch PM inside with it's paymentType
    // || user.paymentMethods.loading
  ) {
    return (
      <AppContainer.CenteredColumn
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Loader appStyles={appStyles} />
      </AppContainer.CenteredColumn>
    );
  }

  return (
    <AppContainer.Content appStyles={appStyles} style={{minHeight: "unset"}}>
      <PurchasePoller T={T} {...context} />
      <Box sx={{ m: "10px 0" }}>
      {separateQRCodeForPayment &&<Tabs
          variant="fullWidth"
          value={tabIndex}
          onChange={handleChange}
          className={classes.tabs}
        >
           <Tab label="Scan to Pay" />
          <Tab label="Scan for Rewards" />
        </Tabs> }
        <Box sx={{ p: "0 12px" }}>
          {!tabIndex && separateQRCodeForPayment ? (
            !showAddCardForm ? (
              <Box mt="24px">
                <Typography component="div" className={classes.title}>
                  {T("Payment Method")}
                </Typography>
                {qrCodeContent}
              </Box>
            ) : (
              <PaymentMethodForm
                appStyles={appStyles}
                pageContext={pageContext}
                T={T}
                user={user}
                order={order}
                paymentTypeDetails={paymentTypeDetails}
                onClose={handleShowAddCardForm}
                location={location}
              />
            )
          ) : (
            <Box mt="24px">
              <Typography component="div" className={classes.title}>
                {T("Scan & Earn Points")}
              </Typography>
              <Box className={classes.qrCodeCard}>
                <QRCode value={qrCodeValue} />
              </Box>
            </Box>
          )}
          {!showAddCardForm && (
            <Box mt="10px">
              <Typography component="div" className={classes.title}>
                {T("Balance")}
              </Typography>
              <Grid container spacing="28px">
                <Grid display="flex" item xs={6}>
                  <Paper className={classes.paper}>
                    <StarIcon className={classes.svg} />
                    <Typography
                      component="div"
                      className={classes.balanceCardTitle}
                      align="center"
                    >
                      {`${rewardPointBalance} pts`}
                    </Typography>
                    <Typography
                      component="div"
                      className={classes.balanceCardSubTitle}
                      align="center"
                    >
                      {T("Points")}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid display="flex" item xs={6}>
                  <Paper className={classes.paper}>
                    <DollarIcon className={classes.svg} />
                    <Typography
                      component="div"
                      className={classes.balanceCardTitle}
                      align="center"
                    >
                      {`${currencySymbol}${amountOnCard.toFixed(2)}`}
                    </Typography>
                    <Typography
                      component="div"
                      className={classes.balanceCardSubTitle}
                      align="center"
                    >
                      {T("Credit")}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </AppContainer.Content>
  );
};

const useStyles = makeStyles()((theme, appStyles) => ({
  svg: {
    "& path": {
      stroke: appStyles.actionColor,
    },
  },
  tabs: {
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.04)",
    "& .MuiTab-root": {
      textTransform: "none",
      fontSize: "16px",
    },
    "& .Mui-selected": {
      fontWeight: "bold",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "24px",
    borderRadius: "10px",
    width: "100%",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    color: appStyles.textColor || "#000000",
    marginBottom: "12px",
  },
  balanceCardTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    color: appStyles.textColor || "#000000",
    marginTop: "22px",
  },
  balanceCardSubTitle: {
    fontSize: "12px",
    fontWeight: "bold",
    color: appStyles.textColor || "#000000",
  },
  slider: {
    height: `274px`,
    width: `calc(100% - 12)`,
    margin: "-6px -12px -6px -6px",
  },
  qrCodeCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "244px",
    backgroundColor: get(appStyles, "PaymentMethodCard.backgroundColor", appStyles.actionColor),
    backgroundImage: get(appStyles, "PaymentMethodCard.backgroundImage"),
    border: `2px solid #FFFFFF`,
    borderRadius: "18px",
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25)",
    boxSizing: "border-box",
    margin: "6px",
  },
}));
