import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "../AppContainer/mui-theme";
import { QRCodeSVG } from "qrcode.react";

const QRCode = ({ value, ...props }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.qrCodeContainer}>
      <QRCodeSVG
        className="fs-unmask"
        value={value}
        style={{ height: 116, width: 116 }}
        {...props}
      />
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  qrCodeContainer: {
    height: 140,
    width: 140,
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
}));

export default QRCode;
