import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PaymentMethodInput from "../PaymentMethodInput";
import { PAYMENT_ID } from "../../api-services/constants";
import StripeProvider from "../StripeProvider";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import { addPaymentMethod, openPaymentAction } from "../../store/order/actions";
import { useDispatch } from "react-redux";
import _, { get } from "lodash";
import AppContainer from "../AppContainer";
import Loader from "../Loader";
import PaymentAPIScriptLoader, {
  shouldLoadScript,
} from "../PaymentAPIScriptLoader";
import { spreedlyTokenize } from "../../api-services/spreedlyAPI";
import { navigateTo, removeParams } from "../../utils/location";
import { makeStyles } from "../AppContainer/mui-theme";

const PaymentMethodForm = ({
  paymentTypeDetails,
  appStyles,
  user,
  T,
  pageContext,
  stripe,
  elements,
  onClose,
  location,
}) => {
  const { classes } = useStyles(appStyles);
  const dispatch = useDispatch();
  const { idRequired } = pageContext.businessAppConfiguration || {};
  const { requireZipCode } = paymentTypeDetails || {};

  const shouldLoadPayment = shouldLoadScript(paymentTypeDetails);
  const [paymentLoaded, setPaymentLoaded] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    //Stripe specific redirect handling
    navigateTo(
      removeParams(
        location,
        "setup_intent",
        "setup_intent_client_secret",
        "redirect_status"
      )
    );
  }, []);

  // const resetCardInput = () => {
  //   _.map(FIELDS, (field) => {
  //     if (this.fieldsRefs[field] && this.fieldsRefs[field].clearInput) {
  //       this.fieldsRefs[field].clearInput();
  //     }
  //   });
  // };

  const isSpreedly = () =>
    _.includes(
      [PAYMENT_ID.SPREEDLY_TOAST, PAYMENT_ID.SPREEDLY_PURCHASE],
      paymentTypeDetails.paymentType
    );

  const isCardConnect = () => {
    return paymentTypeDetails.paymentType === PAYMENT_ID.CARD_CONNECT;
  };

  const preTokenizeOrRecacheIfNeeded = (creditCard) => {
    if (isSpreedly()) {
      return spreedlyTokenize({
        fullName: _.get(user, "userDetails.data.name"),
        ..._.pick(creditCard, ["month", "year", "zipCode"]),
      });
    }
    if (isCardConnect() && document.getElementById("cardConnectToken")) {
      const token = _.get(creditCard, "token");

      return Promise.resolve({
        token,
        paymentMethod: {
          ..._.pick(creditCard, ["month", "year"]),
          last_four_digits: token.substr(-4),
        },
      });
    }
    return Promise.resolve();
  };

  const onSubmit = (e) => {
    if (paymentTypeDetails.paymentType === PAYMENT_ID.STRIPE) {
      const {
        userDetails: {
          data: { name, phoneNumber, email },
        },
      } = user;
      if (!stripe || !elements) {
        console.error("stripe is not loaded");
        return;
      }

      return stripe
        .confirmSetup({
          elements,
          confirmParams: {
            return_url: window.location.href,
            payment_method_data: {
              billing_details: {
                name,
                phone: phoneNumber,
                email,
              },
            },
          },
        })
        .then((res) => {
          console.log(res, "Stripe Confirm setup intent response");
        })
        .catch((error) =>
          console.error(error, "Stripe Confirm setup intent error")
        );
    }

    if (shouldLoadPayment && !paymentLoaded) {
      console.error("not handled, zooz should be loaded but it failed to load");
      return;
    }

    new Promise((resolve, reject) =>
      ref.current.validate((err, value) => {
        if (err) reject(err);
        resolve(value);
      })
    )
      .then(({ creditCard }) => {
        preTokenizeOrRecacheIfNeeded(creditCard)
          .then((tokenResponse) => {
            dispatch(
              addPaymentMethod({
                creditCard: isSpreedly()
                  ? _.pick(creditCard, ["month", "year"])
                  : isCardConnect()
                  ? {
                      year: _.get(creditCard, "year"),
                      month: _.get(creditCard, "month"),
                      zipCode: _.get(creditCard, "zipCode"),
                    }
                  : creditCard,
                user,
                paymentTypeDetails,
                tokenResponse,
              })
            );
            onClose();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log("Invalid Form - payment details:", error);
      });

    // onClose();
  };

  if (shouldLoadPayment && !paymentLoaded) {
    return (
      <PaymentAPIScriptLoader
        paymentTypeDetails={paymentTypeDetails}
        onLoad={() => setPaymentLoaded(true)}
      />
    );
  }

  return (
    <Stack spacing={1} sx={{ mt: "24px" }}>
      <PaymentMethodInput
        key={1}
        T={T}
        appStyles={appStyles}
        idRequired={idRequired}
        requireZipCode={requireZipCode}
        ref={ref}
        paymentTypeDetails={paymentTypeDetails}
        paymentScriptLoaded={paymentLoaded}
        title={T("Add Credit Card")}
      />
      <Button className={classes.btn} variant="contained" onClick={onSubmit}>
        {T("Add Card")}
      </Button>
      <Button className={classes.btn} variant="outlined" onClick={onClose}>
        {T("Cancel")}
      </Button>
    </Stack>
  );
};

const Wrapper = (props) => {
  const {
    order,
    user,
    paymentTypeDetails,
    pageContext: { business },
    appStyles,
    T,
    onClose
  } = props;

  const dispatch = useDispatch();
  const { classes } = useStyles(appStyles);

  useEffect(() => {
    if (paymentTypeDetails?.paymentType !== PAYMENT_ID.STRIPE) return;

    const currency = business.openChargeCard
      ? business.openChargeCard.price.currency
      : 0;

    dispatch(
      openPaymentAction({
        user,
        paymentTypeIdentifier: paymentTypeDetails?.paymentType,
        currency,
        openForFuturePayment: true,
      })
    );
  }, []);

  if (paymentTypeDetails?.paymentType === PAYMENT_ID.STRIPE) {
    if (get(order, "openPaymentTokens.error")) {
      return (
        <AppContainer.CenteredColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "300px",
          }}
        >
          <Typography className={classes.title}>
            {T("Something went wrong while adding payment method, please try again later.")}
          </Typography>
          <Button sx={{width: 120}} className={classes.btn} variant="outlined" onClick={onClose}>
            {T("Back")}
          </Button>
        </AppContainer.CenteredColumn>
      );
    }

    if (!get(order, "openPaymentTokens.data") || get(order, "openPaymentTokens.loading")) {
      return (
        <AppContainer.CenteredColumn
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "300px",
          }}
        >
          <Loader appStyles={appStyles} />
        </AppContainer.CenteredColumn>
      );
    }

    return (
      <StripeProvider
        options={{ clientSecret: get(order, "openPaymentTokens.data.paymentToken") }}
        paymentTypeDetails={paymentTypeDetails}
      >
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <PaymentMethodForm {...{ stripe, elements, ...props }} />
          )}
        </ElementsConsumer>
      </StripeProvider>
    );
  }

  return <PaymentMethodForm {...props} />;
};

export default Wrapper;

const useStyles = makeStyles()((theme, appStyles) => ({
  btn: {
    fontSize: "20px",
    textTransform: "none",
    padding: "12px",
    borderRadius: 16,
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    color: appStyles.textColor || "#000000",
    marginBottom: "12px",
  },
}));
